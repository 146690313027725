@import '../variables';

.QboAutocomplete__wrapper .FormControl__default-wrapper,
.QboAutocomplete__wrapper.MuiAutocomplete-hasClearIcon .FormControl__default-wrapper {
  &.MuiOutlinedInput-root,
  .MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root {
    padding: 0;
    padding-right: 0;
  }

  .MuiAutocomplete-endAdornment {
    top: inherit;
  }

  .MuiAutocomplete-endAdornment button {
    padding: 0;

    &:hover {
      background: none;
    }
  }

  .MuiAutocomplete-endAdornment button svg {
    font-size: $font-size-large;
    margin-right: .5rem;
    color: $mute-color-2;
  }


  .MuiAutocomplete-input.MuiInputBase-input {
    padding: .8rem 1.2rem;
  }

  .MuiInputAdornment-positionStart + .MuiInputBase-input {
    padding-left: 4rem;
  }
}

.QboAutocomplete__always-show-icon.MuiAutocomplete-hasClearIcon .FormControl__default-wrapper .MuiAutocomplete-endAdornment button svg {
  visibility: visible;
}


.QboAutocomplete__wrapper.MuiAutocomplete-hasClearIcon .FormControl__default-wrapper {
  .MuiAutocomplete-input.MuiInputBase-input {
    padding-right: 3rem;
  }
}

.QboAutocomplete__popper-wrapper {
  .QboAutocomplete__bottom-link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 1rem;
    border-top: $thin-border;
    font-weight: $font-weight-semi-bold;
  }

  ul.MuiAutocomplete-listbox li.MuiAutocomplete-option {
    font-size: $font-size-default;
    font-family: $font-family;
  }

  .QboAutocomplete__loading-text {
    display: flex;
    align-items: center;
    padding: 1.5rem 1rem;
    font-weight: $font-weight-semi-bold;
    color: $mute-color;
  }

  ul.MuiAutocomplete-listbox .MuiListSubheader-root {
    color: $mute-color;
    font-size: $font-size-default;
  }

  .MuiTypography-root mark.QboAutocomplete__mark-label {
    background: none;
    font-weight: $font-weight-bold;
  }
}
