.AccountSettingsMenuSection__wrapper {
  padding: 1.8rem 1.6rem;

  .MuiTypography-root,
  .AccountSettingsMenuSection__list-account-menu {
    font-weight: $font-weight-semi-bold;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  .AccountSettingsMenuSection__list-account-menu {
    margin-top: 1.6rem;

    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      color: $primary-color;
    }

    a svg {
      font-size: $font-size-large;
    }

    a.AccountSettingsMenuSection__active-account-menu {
      color: $active-color;
    }
  }
}
