@import '../variables';

.SettingSetup2FAPage__wrapper {
  .SettingSetup2FAPage__card {
    padding: 2rem 2.8rem;
    display: flex;
    gap: 9.6rem;
  }

  .SettingSetup2FAPage__main-content {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    width: 100%;
  }

  .SettingSetup2FAPage__link-list {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }

  .SettingSetup2FAPage__app-link {
    display: flex;
    align-items: center;
    color: $blue-color;
    font-size: $font-size-default;
    gap: 0.4rem;
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;

    svg {
      right: -0.8rem;
      bottom: -0.8rem;
      transform: rotate(30deg);
      font-size: 1.6rem;
    }
  }

  .SettingSetup2FAPage__setup_button {
    margin-top: 1.6rem;
  }

  .SettingSetup2FAPage__toggle-info-box {
    display: flex;
    flex-direction: row;
    border: $thin-border;
    border-radius: 0.4rem;
    padding: 1.2rem 2rem;
    justify-content: space-between;
  }

  .SettingSetup2FAPage__toggle-info-text {
    display: flex;
    flex-direction: column;
  }

  .SettingSetup2FAPage__text-button-reset {
    color: $danger-color-3;
    font-size: $font-size-default;
    width: fit-content;
  }
}
