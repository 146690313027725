@import '../variables';

$drawer-height-select-version: 6.4rem;

.DrawerObjectContentSection__wrapper {
  position: relative;

  .CardBreadcrumbsBlock__wrapper {
    padding: 1.25rem 3rem;
    height: $drawer-height-breadcrumbs;
  }

  .DrawerObjectContentSection__select-version {
    padding: 1.05rem 3rem;
    height: $drawer-height-select-version;
    border-bottom: $thin-border;

    .QboSelectField__select svg[data-testid='CalendarMonthIcon'] {
      color: $blue-color;
    }
  }

  .QboTable__wrapper .MuiTableContainer-root {
    max-height: calc(
      100vh - ($drawer-height-breadcrumbs + $drawer-height-heading + $drawer-height-select-version)
    );
  }

  .QboTable__wrapper table .MuiTypography-root[title] {
    max-width: 22rem;
  }

  .DrawerObjectContentSection__select-version .MuiButtonBase-root {
    height: 3.4rem;
    align-self: center;
  }

  .DrawerObjectContentSection__right-button-search {
    display: flex;
    gap: 1.2rem;
  }

  .DrawerObjectContentSection__banner-info-deleted {
    display: flex;
    padding: 1.05rem 3rem;
    border-bottom: $thin-border;
    height: 3.2rem;
    align-content: center;
    background-color: $bg-warning;
  }
}

.DrawerObjectContentSection__with-bottom-bar {
  .QboTable__wrapper .MuiTableContainer-root {
    max-height: calc(
      100vh -
        (
          $drawer-height-breadcrumbs + $drawer-height-heading + $drawer-height-bottom-bar +
            $drawer-height-select-version - 0.1rem
        )
    );
  }
}

.DrawerObjectContentSection__wrapper {
  .DrawerObjectContentSection__title {
    .QboCardListItem__content {
      flex: 1 1;
      min-width: 0;

      span {
        font-size: 1.8rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
