.ModalDateTime__wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  .ModalDateTime__date-time {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    align-items: center;

    .MuiFormControl-root {
      width: 100%;
    }
  }

  .MuiTypography-root {
    font-size: $font-size-small;
    font-weight: $font-weight-default;
    color: $primary-color;
    white-space: pre-wrap;
  }

  .ModalDateTime__description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
    padding: 0.8rem 1.6rem;
    gap: 0.8rem;

    /* gold/gold10 */

    background: $bg-warning;
    /* gold/gold20 */

    border: 0.1rem solid $border-warning;
    border-radius: 0.4rem;

    .MuiTypography-root span {
      font-size: $font-size-default;
      color: $black-color;

      span {
        font-weight: $font-weight-bold;
      }
    }
  }
}

.ModalConfirmation__wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  .MuiTypography-root {
    white-space: pre-wrap;
  }
  .ModalConfirmation__preview-title {
    .MuiTypography-root {
      font-weight: $font-weight-semi-bold;
    }
  }

  .ModalConfirmation__notes {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;

    .ModalConfirmation__notes-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.4rem;

      .MuiTypography-root {
        font-size: $font-size-extra-small;
        color: $mute-color;

        &:first-child {
          font-weight: $font-weight-semi-bold;
          font-size: $font-size-default;
          color: $black-color;
        }
      }
    }

    .ModalConfirmation__text-field .MuiInputBase-input {
      width: 100%;
      font-weight: $font-weight-default;
      color: $primary-color;
    }
  }

  .ModalConfirmation__description .MuiTypography-root span {
    font-size: $font-size-default;
    color: $primary-color;

    span {
      font-weight: $font-weight-semi-bold;
      color: $black-color;
    }
  }
}

.ModalDownloadStatus__wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  .MuiTypography-root:first-child {
    color: $primary-color;
    white-space: initial;
  }

  .ModalDownloadStatus__download-list-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    .ModalDownloadStatus__content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .ModalDownloadStatus__content-left {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex: 1;
      }

      .ModalDownloadStatus__content-left .MuiTypography-root {
        font-size: $font-size-small;
        color: $primary-color;

        &:last-child {
          font-size: $font-size-default;
          font-weight: $font-weight-semi-bold;
          color: $black-color;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
