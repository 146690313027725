.FilterToolbar__wrapper {
    border-top: $thin-border;
    padding: 1.6rem 2.8rem;
    min-height: 5.2rem;
    gap: 0;
  
    .FilterToolbar__left-content{
      display: flex;
      flex-direction: row;
      gap: 1.2rem;
      align-items: baseline;
  
      .MuiTypography-root {
        font-family: $font-family;
        font-weight: $font-weight-semi-bold;
        font-size: $font-size-small;
        line-height: 2rem;
  
        /* identical to box height, or 167% */
        letter-spacing: 0.002rem;
  
        /* font/font90 */
        color: $primary-color;
      }
  
      .FilterToolbar__chip-wrapper{
        display: flex;
        flex-direction: row;
        gap: 1.2rem;
        flex-wrap: wrap;
        flex: 1;
      }
    }
  }