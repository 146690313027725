@import '../variables';

footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $bg-white-color;
  padding: 0px;
  border-top: $thin-border;
  z-index: 1;
  color: $mute-color;
  font-size: $font-size-extra-small;
  font-family: 'Inter';

  .footer__flex-wraper {
    display: flex;
    justify-content: space-between;;
  }

  a {
    font-size: $font-size-extra-small !important;
    font-family: 'Inter';
  }

  p {
    margin: 0px;
    font-size: $font-size-extra-small !important;
  }
}
