@import '../variables';

.MuiSnackbar-root.QboSnackbar__wrapper .QboSnackbar__initial-text .MuiTypography-root {
  background-color: $bg-white-color;
  color: $primary-color;
  padding: 1rem;
  border-radius: 0.5rem;
}

.MuiSnackbar-root.QboSnackbar__wrapper {
  .QboSnackbar__alert .MuiAlert-action {
    padding: 0.8rem 0 0.8rem 0.8rem;
  }
}
