@import '../variables';

.BackupListItemBlock__wrapper {
  padding: 1.6rem 2.8rem;
  border-top: $thin-border;
  min-height: 7.5rem;

  .BackupListItemRightBlock__buttons-wrapper {
    display: flex;
    gap: 0.8rem;
  }

  .QboCardListItem__left-content {
    display: flex;
    align-self: baseline;
  }

  &.BackupListItemBlock__active {
    cursor: pointer;
  }

  &.BackupListItemBlock__hover {
    background-color: $bg-gray-color-1;
  }
}

.BackupListItemNotFound__border-top {
  padding: 1.6rem 2.8rem;
  border-top: $thin-border;
  min-height: 7.5rem;

  h4 {
    font-size: $font-size-extra-large;
    color: $black-color;
  }

  p {
    color: $primary-color;
  }
}
