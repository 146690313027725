@import '../variables';

.QboCardCaption__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
  gap: .4rem;

  .QboCardCaption__img {
    margin-bottom: 1.4rem;
  }
}
