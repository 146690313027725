.BackupStatusItemBlock__wrapper .BackupStatusItemBlock__badge-wrapper {
  .BackupStatusItemBlock__content {
    display: flex;
    align-items: center;
    gap: .8rem;
    margin-top: .1rem;
  }

  .BackupStatusItemBlock__content p {
    font-size: $font-size-small;
    font-weight: $font-weight-semi-bold;
  }

  .QboLinearProgress__wrapper {
    margin-top: 1rem;
  }

  .QboCardListItem__left-content {
    display: flex;
    align-self: baseline;
    margin-top: 1rem;
  }
}

.BackupStatusItemBlock__wrapper .BackupStatusItemBlock__auth-tips-wrapper {
  margin-top: 1.8rem;
  margin-bottom: 1.2rem;
}

.BackupStatusItemBlock__wrapper .BackupStatusItemBlock__download-wrapper {
  margin-top: 1rem;
}
