@import '../variables';

.DrawerReplicateGuideSection__wrapper {
  .DrawerReplicateGuideSection__content {
    display: flex;
    flex-direction: column;
    padding: 1.6rem 4rem 7rem 4rem;
    white-space: initial;
    line-height: 2.8rem;
    gap: 1.6rem;

    .MuiTypography-root {
      line-height: 2.8rem;
    }
  }
}

.DrawerReplicateGuideSection__wrapper {
  .DrawerReplicateGuideSection__image-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 0.6rem;
  }

  .DrawerReplicateGuideSection__content-helper {
    font-size: $font-size-default;
    white-space: initial;
  }

  .DrawerReplicateGuideSection__content-title {
    font-size: $font-size-extra-large;
    font-weight: $font-weight-bold;
  }

  .DrawerReplicateGuideSection__tips-box {
    color: $black-color;
    margin-top: 0;
  }
}

.DrawerReplicateGuideSection__wrapper {
  .DrawerReplicateGuideSection__content .DrawerReplicateGuideSection__paragraph-wrapper {
    display: flex;
    flex-direction: column;
    white-space: initial;
    gap: 1.6rem;
  }

  .DrawerReplicateGuideSection__content .DrawerReplicateGuideSection__paragraph-wrapper {
    .DrawerReplicateGuideSection__paragraph-title {
      font-size: $font-size-large;
      font-weight: $font-weight-bold;
      line-height: 3.2rem;
    }

    .DrawerReplicateGuideSection__paragraph-sub-title {
      font-size: 1.6rem;
      font-weight: $font-weight-bold;
      line-height: 2.4rem;
    }

    .DrawerReplicateGuideSection__paragraph-description {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      font-size: $font-size-default;
      font-weight: $font-weight-default;
      color: $primary-color;

      p {
        margin: 0;
      }
    }

    .DrawerReplicateGuideSection__paragraph-list {
      font-size: $font-size-default;
      font-weight: $font-weight-default;
      color: $primary-color;

      ul {
        margin-block-start: 0.8rem;
        margin-block-end: 0;
      }

      ol {
        margin-block-start: 0.8rem;
        margin-block-end: 0;
      }
    }
  }
}
