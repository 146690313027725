@import '../variables';

.Box__shadow-depth-10 {
  box-shadow: $shadow-depth-10;
}

.Box__shadow-depth-100 {
  box-shadow: $shadow-depth-100;
}

.Box__shadow-depth-1000 {
  box-shadow: $shadow-depth-100;
}

.Box__main-with-padding {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
