@import '../variables';

.SecondBar__default {
  height: auto;
  padding: 0.45rem 1.5rem;
  background-color: $bg-gray-color-1 !important;
  border-bottom: $thin-border;
  position: fixed;
  top: 5.7rem;
  z-index: 1;
  text-align: center;
  display: block;
  font-size: $font-size-small;
  color: $mute-color;
  z-index: 2;

  .SecondBar__default-flex {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  p, a {
    font-size: $font-size-small;
  }
}

@media screen and (min-width: 600px) {
  .SecondBar__default {
    top: 5.6rem;
  }
}
