@import '../variables';

.ErrorPage__wrapper {

  h4.MuiTypography-root.MuiTypography-h4 {
    color: $black-color;
    font-size: $font-size-h2;
  }

  p.MuiTypography-root {
    max-width: 115vh;
    white-space: inherit;
    text-align: center;
    font-size: $font-size-large;
    line-height: 3.2rem;
  }

  img.QboCardCaption__img {
    margin-bottom: 4.4rem;
  }
}
