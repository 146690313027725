@import '../variables';

.Card__default {
  width: 100%;

  @each $key, $val in $shadow-list {
    &.Card__box-shadow-#{$key},
    .Card__box-shadow-#{$key} {
      box-shadow: #{$val};
    }
  }

  .QboCardHeader__wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    margin-bottom: 2rem;
  }
}

.QboCard__wrapper .QboCard__title {
  font-weight: $font-weight-semi-bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.QboCard__wrapper .QboCard__title-card {
  padding: 1.6rem 2.8rem;
  box-shadow: $inset-shadow;
}

.QboCardWrapper__wrapper {
  gap: 2.4rem;
  display: flex;
  flex-direction: column;
}

.QboCardListItem__wrapper {
  gap: 2rem;

  .QboCardListItem__content {
    flex-grow: 1;
    gap: 0.35rem;
    display: flex;
    flex-direction: column;

    .QboCardListItem__info,
    .QboCardListItem__info-mute {
      font-size: $font-size-small;
      font-weight: $font-weight-default;
    }

    .QboCardListItem__info-mute {
      color: $mute-color-3;
    }
  }

  .QboCardListItem__left-content,
  .QboCardListItem__right-content {
    display: flex;
  }
}

.QboCardListItem__loading {
  padding: 1.6rem 2.8rem;
  border-top: $thin-border;
  min-height: 7.5rem;

  .QboCardListItem__left-content {
    display: flex;
    align-self: baseline;
  }
}

.QboCardListItem__wrapper.QboCardListItem__compact-padding {
  padding: 1.3rem 1.6rem;
}

.DashboardUsageCard__wrapper {
  padding: 2.2rem 2.8rem !important;

  h2 {
    margin: 0px;
    font-weight: $font-weight-bold;
    font-size: $font-size-extra-large;
    vertical-align: middle;

    span {
      font-size: $font-size-default;
      vertical-align: top;
      font-weight: $font-weight-default;
    }
  }

  .QboLinearProgress__wrapper {
    max-width: 40rem;
  }

  .QboButton__default {
    padding: 1rem 1.5rem;
  }
}

.CardBreadcrumbsBlock__wrapper {
  padding: 1.3rem 1.6rem;
  border-bottom: $thin-border;
}

.QboCardListItemStatus__wrapper {
  gap: 2rem;

  .QboCardListItemStatus__content {
    flex-grow: 1;
    gap: 0.35rem;
    display: flex;
    flex-direction: column;

    .QboCardListItemStatus__info,
    .QboCardListItemStatus__info-mute {
      font-size: $font-size-small;
      font-weight: $font-weight-default;
    }

    .QboCardListItemStatus__info-mute {
      color: $mute-color-3;
    }
  }

  .QboCardListItemStatus__left-content,
  .QboCardListItemStatus__right-content {
    display: flex;
  }
  .QboCardListItemStatus__right-content-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .QboCardListItemStatus__top-right-content {
    display: flex;
    flex: 1;
    align-items: center;
  }
  .QboCardListItemStatus__button-right-content {
    max-width: none;
  }
}

.QboCardListItemStatus__loading {
  padding: 1.6rem 2.8rem;
  border-top: $thin-border;
  min-height: 7.5rem;

  .QboCardListItemStatus__left-content {
    display: flex;
    align-self: baseline;
  }
}

.QboCardListItemStatus__wrapper.QboCardListItemStatus__compact-padding {
  padding: 1.3rem 1.6rem;
}
