.BackupRealmItemPage__wrapper {

  .SearchBlock__wrapper .FormControl__default-wrapper,
  .SearchBlock__wrapper .FormControl__default-wrapper .MuiInputBase-input {
    max-width: 44rem;
  }

  .SearchBlock__wrapper .FormControl__default-wrapper .MuiInputBase-input {
    font-size: $font-size-small;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }

  .SearchBlock__wrapper.QboCardListItem__compact-padding {
    padding: 1.8rem 1.6rem;
  }

  .BackupRealmItemPage__wrapper {
    padding: 1.4rem 1.35rem;
  }

  .BackupRealmItemPage__header-block {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .HeaderPageBlock__wrapper {
      margin-bottom: 0;
    }
  }

  .SearchBlock__wrapper .MuiButtonBase-root {
    height: 3.4rem;
  }

  .BackupRealmItemPage__right-button-search {
    display: flex;
    gap: 1.2rem;
  }

  .BackupRealmItemPage__search-wrapper .QboCardListItem__content{
    flex-direction: row;
    gap: 1.2rem;

    .MuiAutocomplete-root {
      display: flex;
      flex-grow: 1;
      max-width: 44rem;
    }
  }
}
