@import '../variables';

.QboBreadcrumbs__wrapper {
  li svg {
    margin-right: .3rem;
  }

  li.MuiBreadcrumbs-separator {
    color: $mute-color-2;
    margin: auto .3rem;

    svg {
      margin-right: 0;
      font-size: 1.4rem;
    }
  }

  li .MuiTypography-root {
    font-size: 1.2rem;
    display: flex;
    gap: 2px;
    align-items: center;
  }

  li.MuiBreadcrumbs-li .MuiTypography-root {
    display: inline-block;
    max-width: 15rem;
    vertical-align: middle;
    display: inline-block;
  }

  li.MuiBreadcrumbs-li svg {
    vertical-align: sub;
  }
}
