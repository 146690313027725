@import '../variables';

.NotFoundPage__wrapper {
  h1 {
    font-size: $font-size-banner;
  }

  h4.MuiTypography-root.MuiTypography-h4 {
    font-size: $font-size-h2;
  }

  p.MuiTypography-root {
    font-size: $font-size-extra-large;
  }
}
