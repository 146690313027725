@import '../variables';

.QboPagination__wrapper {
  display: flex;
  align-items: center;
  font-size: $font-size-small;

  .MuiPagination-ul .MuiButtonBase-root {
    margin: 0;
  }

  .MuiPagination-ul .MuiButtonBase-root svg {
    font-size: 2.4rem;
  }

  .QboDropdown__wrapper {
    margin-right: 2rem;
  }

  .QboDropdown__wrapper .MuiButtonBase-root {
    font-size: $font-size-small;
    padding-left: 10px;
    padding-right: 10px;
  }

  .QboPagination__control {
    display: flex;
    gap: 1rem;

    button {
      padding: 0;
      height: auto;
      min-width: auto;
    }

    button p {
      height: 2.5rem;
    }

    button svg {
      font-size: 2.5rem;
      color: $black-color;
    }

    button {
      color: $black-color;
      background-color: transparent;
    }

    button:hover,
    button:active,
    button:focus {
      color: $black-color;
      background-color: $bg-gray-color-2;
    }

    button:disabled,
    button.disabled,
    button.Mui-disabled {
      opacity: .4;
    }
  }
}

.QboPagination__dropdown-menu .MuiMenu-list {
  min-width: 6rem;
}
