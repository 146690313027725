@import '../variables';

.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    font-size: $font-size-extra-small;
    font-family: 'Inter';
    color: $white-color;
    font-weight: $font-weight-default;
    line-height: 2rem;
    background-color: $bg-blue-color-3;
  }
}
