@import '../variables';

.FormControl__default-wrapper .QboDatePicker__default {
  display: flex;
  font-family: $font-family;
  font-style: normal;
  font-weight: $font-weight-default;
  font-size: $font-size-small;
  line-height: 2rem;
  color: $black-color;
  width: 100%;

  .MuiInputBase-input {
    font-family: $font-family;
    font-style: normal;
    font-weight: $font-weight-default;
    font-size: $font-size-small;
    line-height: 2rem;
    color: $mute-color-2;
    text-transform: uppercase;
    height: 1.4rem;
  }

  .MuiInputAdornment-root svg {
    color: $primary-color;
    font-size: 1.6rem;
  }
}

.FormControl__default-wrapper .QboDatePicker__default .MuiInputBase-input {
  color: $primary-color;
  width: 100%;
}
