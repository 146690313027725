.ModalRestore__wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  .ModalRestore__date-time {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    align-items: center;

    .MuiFormControl-root {
      width: 100%;
    }
  }

  .MuiTypography-root {
    font-size: $font-size-small;
    font-weight: $font-weight-default;
    color: $primary-color;
    white-space: pre-wrap;
  }

  .ModalRestore_tips-box-notes {
    p {
      font-size: $font-size-default;
      color: $black-color;

      &:first-child {
        font-weight: $font-weight-bold;
      }
    }

    .ModalRestore_tips-box-button {
      svg {
        transform: rotate(45deg);
      }
    }
  }
}

.ModalConfirmationRestore__wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  .MuiTypography-root {
    white-space: pre-wrap;
  }
  .ModalConfirmationRestore__preview-title {
    .MuiTypography-root {
      font-weight: $font-weight-semi-bold;
    }
  }

  .ModalConfirmationRestore__notes {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;

    .ModalConfirmationRestore__notes-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.4rem;

      .MuiTypography-root {
        font-size: $font-size-extra-small;
        color: $mute-color;

        &:first-child {
          font-weight: $font-weight-semi-bold;
          font-size: $font-size-default;
          color: $black-color;
        }
      }
    }

    .ModalConfirmationRestore__text-field .MuiInputBase-input {
      width: 100%;
      font-weight: $font-weight-default;
      color: $primary-color;
    }
  }

  .ModalConfirmationRestore_tips-box-notes {
    ol {
      padding-inline-start: 2rem;
    }

    b {
      font-weight: $font-weight-bold;
    }
  }

  .ModalConfirmationRestore_tips-box-notes-item-version {
    p {
      font-size: $font-size-small;
      span {
        font-size: $font-size-default;
      }
    }
  }

  .ModalConfirmationRestore_tips-box-guides {
    p {
      font-size: $font-size-default;
      color: $black-color;

      &:first-child {
        font-weight: $font-weight-bold;
      }
    }

    .ModalConfirmationRestore_tips-box-button {
      svg {
        transform: rotate(45deg);
      }
    }
  }
}
