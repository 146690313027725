@import '../variables';

.QboAccordion__wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .MuiAccordion-root.QboAccordionRoot__wrapper {
    border: $thin-border;
    border-radius: .4rem;
    background: $bg-white-color;

    .MuiButtonBase-root {
      background: $bg-white-color;
      border-radius: .4rem;
      padding: 0.8rem 1.6rem;
    }

    .MuiAccordionDetails-root {
      border-top: $thin-border;
    }

    .MuiAccordionSummary-content {
      margin: 0;
      align-items: center;
      gap: 1.8rem;
    }

    .MuiAccordionSummary-expandIconWrapper {
      color: $active-color;
    }

    .QboAccordionRoot__caption {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
    }

    .QboAccordionRoot__caption .QboAccordionRoot__heading {
      font-weight: $font-weight-semi-bold;
    }

    .QboAccordionRoot__caption .QboAccordionRoot__subtitle {
      font-size: $font-size-small;
      font-weight: $font-weight-default;
      color: $primary-color;
    }

    .QboAccordionRoot__icon {
      height: 2.3rem;

      svg {
        font-size: 2.3rem;
      }
    }
  }

  .QboAccordionRoot__wrapper.QboAccordionRoot__no-children {
    .MuiButtonBase-root {
      cursor: default;
    }

    .MuiAccordionSummary-expandIconWrapper {
      display: none;
    }

    .Mui-disabled {
      background-color: $white-color;
      opacity: 1;
    }
  }

  .QboAccordionRoot__wrapper.QboAccordionRoot__full-content .MuiAccordionDetails-root {
    padding: 0;
  }
}
