@import '../variables';

.BackupNowConfirmation__wrapper {
  .BackupNowConfirmation__body {
    p:first-child,
    p:last-child {
      margin-top: 0;
    }

    p {
      line-height: 2.8rem;
      letter-spacing: 0.002rem;
      color: $primary-color;
    }
  }
}
