@import '../variables';

.QboBadge__default {
  font-family: $font-family;
  box-shadow: none !important;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;

  .QboBadge__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: $font-size-small;
    font-family: $font-family;
    letter-spacing: 0.02rem;
    height: 2.4rem;
    border: 1px solid;
    border-radius: 0.4rem;
    font-weight: $font-weight-semi-bold;
  }

  .QboBadge__wrapper.MuiChip-sizeMedium {
    font-size: $font-size-default;
  }

  .QboBadge__info-icon {
    font-size: 1.6rem;
    color: $bg-blue-color;
    margin-left: 0.93rem;
  }
}

.QboBadge__default .QboBadge__wrapper .MuiChip-icon {
  font-size: $font-size-small;
}

// badge
// Type: Error
.QboBadge__default .QboBadge__wrapper.MuiChip-colorError {
  color: $danger-color;
  border-color: $border-danger;

  // filled variant
  &.MuiChip-filledError {
    background-color: $bg-danger;
  }

  .MuiChip-icon {
    color: $danger-color-3;
  }
}

// badge
// Type: Warning
.QboBadge__default .QboBadge__wrapper.MuiChip-colorWarning {
  color: $warning-color;
  border-color: $border-warning;

  // filled variant
  &.MuiChip-filledWarning {
    background-color: $bg-warning;
  }

  .MuiChip-icon {
    color: $warning-color-2;
  }
}

// badge
// Type: Primary
.QboBadge__default .QboBadge__wrapper.MuiChip-colorPrimary {
  color: $primary-color-2;
  border-color: $border-primary;

  // filled variant
  &.MuiChip-filledPrimary {
    background-color: $bg-primary;
  }

  .MuiChip-icon {
    color: $bg-blue-color;
  }
}

// badge
// Type: Success
.QboBadge__default .QboBadge__wrapper.MuiChip-colorSuccess {
  color: $success-color;
  border-color: $border-success-2;

  // filled variant
  &.MuiChip-filledSuccess {
    background-color: $bg-success-2;
  }

  .MuiChip-icon {
    color: $bg-icon-success-2;
  }
}

// badge
// Type: Info
.QboBadge__default .QboBadge__wrapper.MuiChip-colorInfo {
  color: $primary-color;
  border-color: $border-info;

  // filled variant
  &.MuiChip-filledInfo {
    background-color: $bg-gray-color;
  }

  .MuiChip-icon {
    color: $primary-color;
  }
}

// badge
// Type: Partially Success
.QboBadge__default .QboBadge__wrapper.MuiChip-colorSecondary {
  color: $success-color;
  border-color: $border-success-2;

  // filled variant
  &.MuiChip-filledSecondary {
    background-color: $bg-success-2;
  }

  .MuiChip-icon {
    color: $black-color-3;
  }
}

//Style filter badges
.QboBadge__chip-filter .MuiChip-root {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.4rem 0.8rem;
  gap: 0.8rem;
  height: 2.8rem;

  /* grey/grey30 */
  background: $bg-gray-color;
  border-radius: 0.4rem;
  border: none;

  .MuiChip-label {
    font-family: $font-family;
    font-weight: $font-weight-default;
    font-size: $font-size-small;
    line-height: 2rem;

    /* identical to box height, or 167% */
    letter-spacing: 0.002rem;

    /* font/font90 */
    color: $primary-color;
  }

  svg {
    font-size: 1.2rem;
  }
}
