@import '../variables';

.DrawerRestoreInfoSection__wrapper {
  .DrawerRestoreInfoSection__content {
    padding: 1.6rem 2rem;
    .QboDrawerListWrapper__wrapper {
      &:last-child {
        padding-top: -2.4rem;
      }
    }
  }

  .MuiAccordion-root.QboAccordionRoot__wrapper .MuiButtonBase-root {
    padding: 1.4rem 1.6rem;
  }

  .DrawerRestoreInfoSection__status-id {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.4rem
  }

  .DrawerRestoreInfoSection__status-id .MuiLoadingButton-root {
    display: flex;
    justify-content: flex-start;
    padding: 0;
    height: 0;

    .MuiButton-startIcon {
      margin: 0;
    }
  }
}

.DrawerRestoreInfoSection__wrapper {
  .DrawerRestoreInfoSection__card-list-value {
    font-weight: $font-weight-semi-bold;
  }
}

.DrawerRestoreInfoSection__wrapper .DrawerRestoreInfoSection__accordion-information {
  .MuiAccordion-root.QboAccordionRoot__wrapper,
  .MuiAccordion-root.QboAccordionRoot__wrapper .MuiAccordionDetails-root {
    border: 0;
    border-top: 0;
  }

  .MuiAccordion-root.QboAccordionRoot__wrapper .MuiAccordionDetails-root {
    padding: 2.4rem 2.2rem;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  .MuiAccordionSummary-root {
    border: $thin-border;
    border-color: $border-form-color;
    padding: 1.4rem 1.6rem;

    svg {
      font-size: $font-size-large;
    }
  }

  .MuiAccordion-root.QboAccordionRoot__wrapper .MuiAccordionSummary-expandIconWrapper {
    color: $mute-color;
  }

  .QboAccordionRoot__caption {
    font-weight: $font-weight-bold;
  }
}

.DrawerRestoreInfoSection__wrapper .DrawerRestoreInfoSection_tips-box-wrapper {
  color: $black-color;
  font-weight: $font-weight-default;
  margin-top: -2.4rem;

  .DrawerRestoreInfoSection_tips-box-notes {
    p:first-child {
      font-weight: $font-weight-semi-bold;
      font-size: $font-size-small;
    }
  }

  .DrawerRestoreInfoSection_tips-box-warning {
    p span {
      font-weight: $font-weight-bold;
    }
  }
}
