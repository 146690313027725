.ReplicateStatusItemBlock__wrapper .ReplicateStatusItemBlock__badge-wrapper {
  .ReplicateStatusItemBlock__content {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    margin-top: 0.1rem;
  }

  .ReplicateStatusItemBlock__content p {
    font-size: $font-size-small;
    font-weight: $font-weight-semi-bold;
  }

  .QboLinearProgress__wrapper {
    margin-top: 1rem;
  }

  .QboCardListItem__left-content {
    display: flex;
    align-self: baseline;
    margin-top: 1rem;
  }
}

.ReplicateStatusItemBlock__wrapper .ReplicateStatusItemBlock__auth-tips-wrapper {
  margin-top: 1.8rem;
  margin-bottom: 1.2rem;

  ol {
    padding-inline-start: 2rem;
  }
}

.ReplicateStatusItemBlock__wrapper .ReplicateStatusItemBlock__download-wrapper {
  margin-top: 1rem;
}
