@import '../variables';

.QboModal__default {
  font-family: $font-family;
  font-style: normal;
  font-weight: $font-weight-default;
  font-size: $font-size-small;
  line-height: 2rem;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  outline: 0;

  .QboModal__wrapper {
    display: flex;
    flex-direction: row;
    width: 46rem;
    background-color: $bg-white-color;
    border: none;
    box-shadow: $shadow-depth-100;
    border-radius: 0.4rem;
    margin: 7rem auto;
    color: $black-color;
    overflow: hidden;
    outline: 0;

    .QboModal__main-wrapper-with-side-content {
      box-shadow: $end-shadow;
    }

    .QboModal__side-content {
      display: flex;
      flex-direction: column;
      background-color: $bg-gray-color-6;

      .QboModal__side-content-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 1.2rem 1.2rem 0;
      }

      .QboModal__side-content-title {
        display: flex;
        justify-content: center;
      }

      .QboModal__side-content-box {
        width: 36rem;
        max-height: 80rem;
        overflow: auto;
      }
    }
  }

  .QboModal__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    padding: 1.2rem 2rem;
    box-shadow: $inset-shadow;
  }

  .QboModal__header .MuiTypography-root.MuiTypography-h5 {
    font-weight: $font-weight-bold;
    line-height: 3.2rem;
    color: $black-color;
  }

  .QboModal__close-icon {
    display: flex;
    align-items: center;

    svg {
      font-size: $font-size-extra-large;
    }
  }

  .QboModal__body {
    padding: 2rem;
    overflow-x: hidden;
    max-height: 86rem;
  }

  .QboModal__footer {
    .MuiLoadingButton-root {
      width: 100%;
    }

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2.8rem 2rem;
    padding-top: 0;
    gap: 1rem;
  }

  .QboModal__button-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .MuiLoadingButton-root {
      width: auto;
    }
  }

  .QboModal__button-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .MuiLoadingButton-root {
      width: auto;
    }
  }

  .QboModal__button-no-padding button {
    padding: 0;
    height: 0;
    min-width: 0;
  }

  .Modal__card-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.2rem 2rem;
    gap: 0.8rem;

    background: $bg-white-color;

    border: 0.01rem solid $border-form-color;
    border-radius: 0.4rem;

    .Modal__list-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1.6rem;

      svg {
        font-size: 2.4rem;
        color: $primary-color;
      }
    }
  }
}
