@import '../variables';

.SettingChangePasswordPage__wrapper {
  .SettingChangePasswordPage__main-content {
    padding: 2rem 2.8rem;
  }

  .SettingChangePasswordPage__form .QboFormGroup__wrapper {
    border-right: $thin-border;
    padding-right: 3.2rem;
  }

  .SettingChangePasswordPage__form-requirement {
    padding-top: 3rem;

    h6 {
      margin-top: -0.3rem;
    }

    .SettingChangePasswordPage__form-requirement-wrapper {
      margin-top: 3rem;
    }

    .SettingChangePasswordPage__form-requirement-wrapper ul {
      margin: 0;
      list-style: none;
      padding: 0;
      margin-top: 1.6rem;

      li p {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        gap: .933rem;
      }

      li .MuiSvgIcon-root {
        color: $mute-color-2;
      }

      li .MuiTypography-colorSuccess .MuiSvgIcon-root {
        color: $tosca-color;
      }
    }
  }

  .SettingChangePasswordPage__submit-button {
    text-align: right;
  }
}
