@import '../variables';

.Drawer__default .MuiDrawer-root .MuiDrawer-paper {
  box-shadow: $shadow-depth-10 !important;
  border-right: $thin-border;
}

.Drawer__default hr.MuiDivider-root {
  border-color: $border-color;
  margin-left: 0.7rem;
  margin-right: 0.7rem;
}

.Drawer__default.Drawer__default-close {
  .QboDrawerMenuItem .MuiListItemButton-root {
    padding-left: 1.7rem;
  }
}

.QboDrawerMenuItem {
  .MuiListItemText-root > span,
  .MuiTypography-root {
    font-size: $font-size-default !important;
    font-weight: $font-weight-semi-bold;
    color: $primary-color;
    font-family: 'Inter';
  }

  .MuiListItemIcon-root,
  .MuiTypography-root {
    min-width: inherit;
    margin-right: .3rem;
  }

  .MuiListItemButton-root,
  .MuiTypography-root {
    padding: 0.65rem 1.4rem;
  }

  &.QboDrawerMenuItem__active svg,
  &.QboDrawerMenuItem__active .MuiListItemText-root > span,
  &.QboDrawerMenuItem__active .MuiTypography-root {
    color: $active-color;
  }
}

.Drawer__default .DrawerSubHeader__default {
  font-size: $font-size-small !important;
  font-weight: $font-weight-semi-bold !important;
  color: $mute-color-2 !important;
  line-height: inherit;
  margin-top: 1.4rem;
  letter-spacing: 0.02rem;
}

.Drawer__default .QboDrawerMenuItem__loading-label {
  margin-left: 1.4rem;
}

.Drawer__default-close {
  .QboDrawerMenuItem__loading-label {
    display: none;
  }

  .QboDrawerMenuItem__loading-icon {
    margin-right: 0px;
  }
}

.Drawer__default {
  .Drawer__header {
    padding: 0rem 0.7rem;
  }
  .MuiTypography-root.Drawer__collapse-title {
    display: flex;
    flex: 1;
    font-family: $font-family;
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-default;
    line-height: 2.4rem;
    letter-spacing: 0.002rem;
    color: $primary-color;
    padding-left: .8rem;
  }
}

.Drawer__default {
  @media (min-width: 600px) {
    .MuiDrawer-root .MuiDrawer-paperAnchorLeft > div {
      min-height: 5.5rem;
    }
  }
}

@media (min-width: 600px) {
  .Drawer__default.Drawer__default-close .MuiDrawer-root,
  .Drawer__default.Drawer__default-close .MuiDrawer-root .MuiDrawer-paper {
    width: 5.5rem !important;
  }
}
