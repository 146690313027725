@import '../variables';

.ModalQRCode2FA__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  text-align: center;

  .ModalQRCode2FA__qr-box {
    width: 28rem;
    height: 28rem;
    margin-bottom: 0.4rem;
  }

  .ModalQRCode2FA__button-link {
    color: $blue-color;
    font-size: $font-size-default;
  }
}

.ModalKey2FA__wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  .ModalKey2FA__button-link {
    color: $blue-color;
    font-size: $font-size-default;
  }

  .ModalKey2FA__key-box {
    background-color: $bg-gray-color-1;
    padding: 1rem;
  }
}
