@import '../variables';

.DrawerRealmInfoSection__wrapper {
  .DrawerRealmInfoSection__content {
    padding: 1.6rem 2rem;
  }

  .MuiAccordion-root.QboAccordionRoot__wrapper .MuiButtonBase-root {
    padding: 1.4rem 1.6rem;
  }

  .DrawerRealmInfoSection__content-header-disable {
    cursor: default;
  }
}

.DrawerRealmInfoSection__wrapper .DrawerRealmInfoSection__accordion-information {
  .MuiAccordion-root.QboAccordionRoot__wrapper,
  .MuiAccordion-root.QboAccordionRoot__wrapper .MuiAccordionDetails-root {
    border: 0;
    border-top: 0;
  }

  .MuiAccordion-root.QboAccordionRoot__wrapper .MuiAccordionDetails-root {
    padding: 2.4rem 2.2rem;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  .MuiAccordionSummary-root {
    border: $thin-border;
    border-color: $border-form-color;
    padding: 1.4rem 1.6rem;

    svg {
      font-size: $font-size-large;
    }
  }

  .MuiAccordion-root.QboAccordionRoot__wrapper .MuiAccordionSummary-expandIconWrapper {
    color: $mute-color;
  }

  .QboAccordionRoot__caption {
    font-weight: $font-weight-bold;
  }
}

.DrawerRealmInfoSection__wrapper {
  .DrawerRealmInfoSection__card-list-value {
    font-weight: $font-weight-semi-bold;
  }
}

.DrawerRealmInfoSection__wrapper .DrawerRealmInfoSection-tips-box-wrapper {
  color: $black-color;
  font-weight: $font-weight-default;
  margin-top: -2.4rem;

  .DrawerRealmInfoSection_tips-box-reason {
    p:first-child {
      font-weight: $font-weight-semi-bold;
      font-size: $font-size-small;
    }
  }
}