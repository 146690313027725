@import '../variables';

.StatusCardItemBlock__wrapper {
  padding: 1.6rem 2.8rem;
  border-top: $thin-border;
  min-height: 7.5rem;

  &.StatusCardItemBlock__active {
    cursor: pointer;
  }

  &:hover {
    background-color: $bg-gray-color-1;
  }

  .StatusCardItemBlock__running-progress {
    margin-top: 0.3rem;
  }

  .StatusCardItemBlock__info {
    color: $primary-color;
    font-size: $font-size-small;
    font-family: $font-family;

    strong {
      color: $black-color;
      font-weight: $font-weight-semi-bold;
    }
  }

  .StatusCardItemBlock__badge-download-info .QboBadge__wrapper {
    height: 2rem;
    font-size: $font-size-extra-small;
  }

  .QboCardListItem__content {
    gap: 0.05rem;
  }

  .StatusCardItemBlock__title {
    display: grid;
    align-items: center;
    gap: 0.5rem;
    grid-auto-flow: column;
    justify-content: start;
    padding-right: 2rem;

    svg {
      color: $blue-color;
    }
  }
}

.BackupListItemNotFound__border-top {
  padding: 1.6rem 2.8rem;
  border-top: $thin-border;
  min-height: 7.5rem;

  h4 {
    font-size: $font-size-extra-large;
    color: $black-color;
  }

  p {
    color: $primary-color;
  }
}
