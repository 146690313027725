.ModalDeactivate__wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  .ModalDeactivate__text-field .MuiInputBase-input {
    width: 100%;
    font-weight: $font-weight-default;
    color: $primary-color;
  }

  .ModalDeactivate__box-warning {
    b {
      font-weight: $font-weight-bold;
    }
    white-space: normal;
    color: $primary-color;
    font-size: $font-size-default;
    text-align: justify;
  }
}
