@import '../variables';

.QboRightDrawer__wrapper {
  position: relative;

  .QboRightDrawer__heading {
    padding: 1.425rem 3rem;
    border-bottom: $thin-border;
    height: $drawer-height-heading;

    .MuiTypography-h5 {
      display: flex;
      align-items: center;
      gap: 0.8rem;

      svg {
        font-size: 2.6rem;
      }
    }
  }

  .QboRightDrawer__close-icon {
    display: flex;
    align-items: center;

    svg {
      font-size: $font-size-extra-large;
    }
  }

  .QboRightDrawer__footer-bar {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: $bg-white-color;
    padding: 1.2rem;
    border-top: $thin-border;
    height: $drawer-height-bottom-bar;
  }

}

.QboRightDrawer__wrapper.QboRightDrawer__large-size .MuiDrawer-paper {
  width: 60%;
  max-width: 85.5rem;
}

.QboRightDrawer__wrapper.QboRightDrawer__medium-size .MuiDrawer-paper {
  width: 44.5rem;

  .QboRightDrawer__heading {
    padding: 1.425rem 2rem;
  }
}

.QboDrawerListWrapper__wrapper {
  padding: 2.4rem 2.2rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.QboDrawerListItem__wrapper {
  align-items: center;

  svg {
    font-size: calc($font-size-extra-large + 0.4rem);
  }

  .QboCardListItem__content {
    gap: 0.15rem;
    flex: 1;
    min-width: 0;
  }
}

.QboDrawerBoxItem__wrapper {
  align-items: center;
  border: $thin-border;
  border-color: $border-form-color;
  padding: 1.8rem 1.6rem;
  border-radius: .4rem;

  svg {
    font-size: calc($font-size-extra-large + 0.4rem);
  }

  .QboCardListItem__content {
    gap: 0.15rem;
  }

  .QboDrawerBoxItem__arrow-icon {
    font-size: $font-size-extra-large;
  }

}

.QboDrawerBoxItem__wrapper.QboDrawerBoxItem__with-link {
  cursor: pointer;
  transition: $transition-default;

  .QboDrawerBoxItem__arrow-icon {
    color: $active-color;
  }

  &:hover {
    background-color: $bg-gray-color-1;
    transition: $transition-default;
  }
}

.MuiDrawer-root.QboRightDrawer__show-on-top {
  z-index: 1400;
}