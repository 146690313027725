@import '../variables';

.Navbar__default {
  box-shadow: none !important;
  background-color: $bg-white-color !important;
  border-bottom: $thin-border;
}

.Dropdown__flag-item {
  display: flex;
  gap: 1.9rem;

  img {
    width: 2.1rem;
  }
}

.QboDropdown__menu .Dropdown__flag-item .MuiTypography-root {
  color: $primary-color;
}

.QboDropdown__locale-dropdown {
  .MuiButtonBase-root,
  .MuiButtonBase-root svg {
    font-weight: $font-weight-semi-bold;
  }
}

.Img-flag {
  width: 2.1rem;
}


@media (min-width: 600px) {
  .Navbar__default .MuiToolbar-root {
    min-height: 5.5rem;
    gap: 3rem;
  }
}
