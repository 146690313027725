@import 'variables';

body {
  background-color: $bg-gray-color-2 !important;
}

.IconButton__default {
  &:hover {
    background: none !important;
  }
}

.Img__fluid {
  max-width: 100%;
  height: auto;
}

.InitialBackupSection__wrapper {
  button {
    margin-top: 1rem;
  }
}

.HeaderPageBlock__wrapper {
  margin-bottom: 2rem;

  a.MuiTypography-noWrap {
    line-height: 1.5rem;
  }

  .HeaderPageBlock__navigate {
    display: inline-block;
    font-size: $font-size-small !important;
    cursor: pointer;

    svg {
      font-size: $font-size-small !important;
      margin-right: 0.4rem;
    }

    span,
    svg {
      vertical-align: middle;
    }
  }

  .HeaderPageBlock__heading {
    color: $black-color !important;
  }
}

.Display_hidden {
  display: none;
}

.Page__with-bottom-stepper {
  margin-bottom: 10rem;
}

.SearchBlock__wrapper {
  padding: 2rem 2.8rem;

  .FormControl__default-wrapper,
  .FormControl__default-wrapper .MuiInputBase-input {
    width: 100%;
    max-width: 36.5rem;
  }

  .FormControl__default-wrapper .SearchBlock__cancel-icon svg {
    color: $mute-color-2;
    visibility: hidden;
  }

  .FormControl__default-wrapper .SearchBlock__cancel-icon {
    cursor: default;
    display: flex;
  }

  .SearchBlock__text-field
    input:not([value='']):focus
    + .MuiInputAdornment-root
    .SearchBlock__cancel-icon
    svg {
    visibility: visible;
  }

  .SearchBlock__text-field
    input:not([value='']):focus
    + .MuiInputAdornment-root
    .SearchBlock__cancel-icon {
    cursor: pointer;
  }
}

.PaginationCardblock__wrapper {
  border-top: $thin-border;
  padding: 1.4rem 2.8rem;
}

.QboLogo__wrapper {
  display: flex;
  align-items: center;

  .QboLogo__main {
    max-width: 16.5rem;
    max-height: 3rem;
  }
}

.MuiDrawer-root .MuiBackdrop-root {
  background-color: rgb(0 0 0 / 23%);
}

.DefaultApplication__not-ready {
  position: relative;

  .DefaultApplication__navigation {
    display: none;
  }

  .DefaultApplication__not-ready-loading {
    position: fixed;
    background-color: $bg-gray-color-2;
    display: flex;
  }
}

@media screen and (min-width: 600px) {
  .Box__main-with-padding {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
}
