@import '../variables';

.BottomStepperBlock__wrapper {
  padding-top: 1.2rem !important;
  padding-bottom: 1.2rem !important;
  width: 100%;
  left: 0;
  padding-right: 6rem !important;
  border-top: $thin-border;
  position: absolute;
  bottom: 2.5rem;

  .QboButton__default .MuiButton-endIcon {
    margin-right: -3px;
    margin-left: 8px;
  }

  .QboButton__default .MuiButton-startIcon {
    margin-left: -3px;
    margin-right: 8px;
  }

  &.BottomStepperBlock__no-dots {
    .MuiMobileStepper-dots {
      display: none;
    }

    button {
      padding: 0.8rem 2rem;
      align-items: flex-end;

      .MuiButton-startIcon {
        align-self: center;
      }
    }

    button .MuiTypography-root {
      font-weight: $font-weight-semi-bold;
      text-transform: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
  }
}
