@import '../variables';

.DrawerRealmFilterSection__wrapper {
  .DrawerRealmFilterSection__heading {
    padding: 1.2rem 2rem;
    height: 5.6rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .DrawerRealmFilterSection__content {
    padding: 1.6rem 2rem;
  }

  .MuiAccordion-root.QboAccordionRoot__wrapper .MuiButtonBase-root {
    padding: 1.4rem 1.6rem;
  }

  .DrawerRealmFilterSection__footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1.6rem 2rem;
    gap: 1.2rem;
  }

  .DrawerRealmFilterSection__footer .MuiLoadingButton-root {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.6rem 1.6rem;
    gap: 4rem;

    width: 18.4rem;
    height: 3.6rem;

    /* blue/blue60 */
    background: $bg-blue-color;
    border-radius: 0.4rem;
  }

  .DrawerRealmFilterSection__footer .MuiLoadingButton-root .MuiTypography-root {
    font-style: normal;
    font-size: 1.4rem;
    line-height: 2.4rem;
    letter-spacing: 0.002rem;
  }
}

.DrawerRealmFilterSection__wrapper .DrawerRealmFilterSection__accordion-filter {
  padding: 1.2rem 2rem;
  gap: 1.2rem;
  .MuiAccordion-root.QboAccordionRoot__wrapper {
    border: none;
  }
  .MuiAccordion-root.QboAccordionRoot__wrapper .MuiAccordionDetails-root {
    border: 0;
    border-top: 0;
  }

  .MuiAccordion-root.QboAccordionRoot__wrapper .MuiAccordionDetails-root {
    padding: 1.2rem 1.6rem;
    gap: 1.2rem;
  }

  .MuiAccordion-root.QboAccordionRoot__wrapper .MuiAccordionDetails-root .MuiButton-root {
    padding: 0rem;
  }

  .MuiAccordion-root.QboAccordionRoot__wrapper .MuiAccordionDetails-root .MuiFormGroup-root {
    padding: 0rem;
    margin-left: -0.59rem;
  }
  
  .MuiAccordion-root.QboAccordionRoot__wrapper .MuiButtonBase-root {
    padding: 1.2rem 1.6rem;
    gap: 1.2rem;
  }

  .MuiAccordionSummary-root {
    border: $thin-border;
    border-color: $border-form-color;
    padding: 1.4rem 1.8rem;
  }

  .MuiAccordion-root.QboAccordionRoot__wrapper .MuiAccordionSummary-expandIconWrapper {
    color: $mute-color;
  }

  .QboAccordionRoot__caption {
    font-weight: $font-weight-bold;
  }
}

.DrawerRealmFilterSection__wrapper {
  .DrawerRealmFilterSection__card-list-value {
    font-weight: $font-weight-semi-bold;
  }
}
