@import '../variables';

.ModalDisable2FABlock__wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  .ModalDisable2FABlock__button-link {
    color: $blue-color;
    font-size: $font-size-default;
  }

  .ModalDisable2FABlock__key-box {
    background-color: $bg-gray-color-1;
    padding: 1rem;
  }
}
