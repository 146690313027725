.ModalReplicate__default {
  .QboModal__wrapper {
    width: 84rem;
  }
}

.ModalReplicate__wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .ModalReplicate__date-time {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    align-items: center;

    .MuiFormControl-root {
      width: 100%;
    }
  }

  .ModalReplicate__dropdown-environment {
    .MuiButtonBase-root {
      flex-direction: row;
      border: 1px solid $border-form-color;
      border-radius: 4px;
      color: $black-color;
      height: 4rem;
      margin-right: 2rem;
      width: 100%;
      justify-content: space-between;
    }

    .MuiButtonBase-root svg {
      font-size: $font-size-large;
      color: $black-color;
    }
  }

  .ModalReplicate__quickbooks_auth {
    .Img__fluid {
      width: 12.3rem;
    }

    .QboConnectButton__wrapper img {
      width: 22.3rem;
    }

    border: $thin-border;
    border-radius: 0.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.6rem;
    // height: 16.8rem;
    gap: 0.8rem;
  }

  .ModalReplicate__quickbooks_auth_error {
    border-color: $border-error-2;
  }

  .ModalReplicate__error_message {
    display: flex;
    gap: 0.4rem;
    color: $danger-color-4;

    .MuiTypography-root {
      color: $danger-color-4;
    }

    .MuiSvgIcon-root {
      margin-top: 0.2rem;
    }
  }

  .ModalReplicate__checkbox_confirmation {
    .MuiFormControlLabel-root {
      align-items: flex-start;
    }
    .MuiCheckbox-root {
      padding: 0rem 0.9rem;
    }
    .MuiTypography-root {
      font-weight: $font-weight-default;
      font-size: $font-size-default;
    }
  }

  .ModalReplicate__button-guide {
    display: flex;
    align-items: center;
    color: $blue-color;
    font-size: $font-size-default;
    font-weight: $font-weight-default;
    gap: 0.4rem;
    padding-left: 2.5rem;

    svg {
      font-size: $font-size-small;
      transform: rotate(45deg);
    }
  }

  .ModalReplicate_tips-box-notes {
    p {
      font-size: $font-size-default;
      color: $black-color;

      &:first-child {
        font-weight: $font-weight-bold;
      }
    }

    .ModalReplicate_tips-box-button {
      svg {
        transform: rotate(45deg);
      }
    }
  }

  .ModalReplicate__disclaimer-wrapper {
    padding: 1.2rem 2rem;
    margin-top: 0;

    ol {
      margin-block-start: 0;
      margin-block-end: 0;
      padding-inline-start: 2rem;
    }

    ol li {
      list-style-type: disc;
    }
  }
}

.ModalConfirmationReplicate__wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  .ModalConfirmationReplicate__preview-title {
    .MuiTypography-root {
      font-weight: $font-weight-semi-bold;
    }
  }

  .ModalConfirmationReplicate__notes {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;

    .ModalConfirmationReplicate__notes-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.4rem;

      .MuiTypography-root {
        font-size: $font-size-extra-small;
        color: $mute-color;

        &:first-child {
          font-weight: $font-weight-semi-bold;
          font-size: $font-size-default;
          color: $black-color;
        }
      }
    }

    .ModalConfirmationReplicate__text-field .MuiInputBase-input {
      width: 100%;
      font-weight: $font-weight-default;
      color: $primary-color;
    }
  }

  .ModalConfirmationReplicate_tips-box-notes {
    ol {
      padding-inline-start: 2rem;
    }

    b {
      font-weight: $font-weight-bold;
    }
  }

  .ModalConfirmationReplicate_tips-box-guides {
    p {
      font-size: $font-size-default;
      color: $black-color;

      &:first-child {
        font-weight: $font-weight-bold;
        color: $danger-color-3;
      }

      b {
        font-weight: $font-weight-bold;
      }
    }

    .ModalConfirmationReplicate_tips-box-button {
      svg {
        transform: rotate(45deg);
      }
    }
  }
}

.ModalReplicate__preview-wrapper {
  display: flex;
  margin-bottom: 1.2rem;
  gap: 0.8rem;
  flex-direction: column;

  .ModalReplicate__preview-box {
    flex-direction: row;
    justify-content: space-between;

    .MuiButtonBase-root .MuiTypography-root {
      color: $danger-color-5;
    }
  }
}

.ModalConfirmationValidationReplicate__wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  font-size: $font-size-default;

  .ModalConfirmationValidationReplicate__text-field .MuiInputBase-input {
    height: 1rem;
  }

  .ModalConfirmationValidationReplicate__button-guide {
    display: inline;
    color: $blue-color;
    font-size: $font-size-default;
    font-weight: $font-weight-default;
    margin-top: -0.8rem;

    svg {
      font-size: $font-size-small;
      transform: rotate(45deg);
    }
  }
}

.ModalConfirmationValidationReplicate__description-wrapper {
  color: $primary-color;

  b {
    font-weight: $font-weight-bold;
  }

  .ModalConfirmationValidationReplicate__description-text {
    margin-right: 0.5rem;
  }
}

.ModalReplicate__list-field-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  .MuiTypography-subtitle2:first-child {
    margin-bottom: -0.8rem;
  }
  .MuiTypography-caption:last-child {
    margin-top: -0.4rem;
  }
}
